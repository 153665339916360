<script>
import {mapGetters} from 'vuex';
import pokerCommon from "@/pokerCommon";
import anime from 'animejs';

export default {
  name: "CardDealer",
  data() {
    return {
      duration: 0.6,
      isDealing: false,
      isDeckVisible: false,
      flopCount: 0,
      isWaitingForPlayerSeatsInfo: false,
    }
  },
  computed: {
    ...mapGetters('game', ['roundKey', 'currentRoomState', 'lastRoomState', 'gameStatusName', 'roomStateName', 'playersInfo', 'playerSeats', 'roomInfo']),
    players() {
      return this.playersInfo.filter(player => player.idx !== -1 && player.playState === this.playState.PLAYING);
    },
    decks() {
      return this.playersInfo.filter(player => player.idx !== -1 && player.playState === this.playState.PLAYING).length * 2;
    },
    communityCards() {
      return this.roomInfo.boards || [];
    },
  },
  watch: {
    roundKey() {
      this.isDeckVisible = false;
      this.isWaitingForPlayerSeatsInfo = false;
    },
    playerSeats: {
      immediate: true,
      handler() {
        if (this.playerSeats.length && this.isWaitingForPlayerSeatsInfo) {
          this.startDeal();
        }
      }
    },
    flopCount() {
      this.isDeckVisible = this.flopCount < this.players.length * 2;
    },
    isDeckVisible(value, prev) {
      if (prev === undefined) {
        return;
      }

      if (value === false && prev === true) {
        this.isWaitingForPlayerSeatsInfo = false;
      }
    }
  },
  methods: {
    startDeal() {
      this.isWaitingForPlayerSeatsInfo = this.playerSeats.length === 0;

      if (this.isWaitingForPlayerSeatsInfo) {
        return;
      }

      this.$nextTick(() => {
        this.EventBus.$emit('card-dist-start');
        this.deal();
      });
    },
    animateDeck({$deck, $to, playerIndex, phase, index}) {
      const rectCard = $deck.getBoundingClientRect();
      const rectTarget = $to.getBoundingClientRect();

      const targetX = rectTarget.left - rectCard.left + (rectTarget.width - rectCard.width) / 2;
      const targetY = rectTarget.top - rectCard.top + (rectTarget.height - rectCard.height) / 2;

      const speed = (this.duration * 1000) / (this.players.length || 1);
      const delay = 400 + index * speed;

      anime.timeline({
        begin: () => {
          pokerCommon.playSound('cardhook');
          $deck.style.display = null;
        },
        complete: () => {
          // $deck && $deck.remove(c);
          $deck.style.display = 'none';

          this.EventBus.$emit('card-dist-complete', {
            playerIndex, phase
          });

          this.flopCount++;
        }
      })
        .add({
          targets: $deck,
          translateX: targetX,
          translateY: targetY,
          rotate: 720,
          easing: 'easeOutExpo',
          duration: speed,
          delay
        });
    },
    deal() {
      if (this.isDeckVisible) {
        return;
      }

      this.flopCount = 0;
      this.isDeckVisible = true;

      let phase = 0, deckIndex = 0;

      for (let repeat = 1; repeat <= 2; repeat++) {
        for (let i = 0, len = this.players.length; i < len; i++) {
          const player = this.players[i];
          const seatInfo = this.findPlayerSeat(player.idx);

          this.animateDeck({
            $deck: this.$refs.decks[deckIndex],
            $to: seatInfo.playerRef,
            playerIndex: player.idx,
            phase,
            index: deckIndex,
          });

          deckIndex++;
        }
        phase++;
      }
    },
    findPlayerSeat(playerIndex) {
      return this.playerSeats.find(player => player.playerIndex === playerIndex);
    },
  },
  created() {
    this.EventBus.$on(`card-dist`, this.startDeal);
  },
  beforeDestroy() {
    this.EventBus.$off(`card-dist`);
  }
}
</script>

<template>
  <div class="area_game_card game_card_dealer">
    <div
      ref="decks"
      v-for="i in decks"
      :key="`deck-${i}--${roundKey}`"
      class="card card_back"
      :class="[`card${i}`]"
      :style="{ visibility: isDeckVisible ? 'visible' : 'hidden' }"
    >
      <img src="@/assets/v2/img/card_back_1.webp"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.game_card_dealer {
  justify-content: center;
  transform: translateY(-50%);
}

.card {
  position: absolute;
  width: 3rem;
  height: 4rem;
  z-index: 1;

  img {
    width: 100%;
  }
}
</style>